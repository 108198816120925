import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import React, { useEffect, useRef, useState } from "react";
import smoothscroll from "smoothscroll-polyfill";

import HomeCentralComponent from "../components/home-comp/home-central-comp";
import Layout from "../components/layout";
import Store from "../helpers/store";

const HomePage = ({ data: { home } }) => {
    const [showNext, setShowNext] = useState(false);
    const myRef = useRef(null);

    useEffect(() => {
        smoothscroll.polyfill();
        const mails = document.querySelectorAll("span[data-to]");
        mails.forEach((m) => {
            const to = m.dataset.to;
            const html = m.innerHTML;
            m.innerHTML = `<a href='mailto:${to}@francisco-gutierrez.me'>${html}</a>`;
        });
        const delayedAnim = Math.round(Math.random()*40)/10;
        document.documentElement.style.setProperty('--delay-anim', `-${delayedAnim}s`);
    }, []);

    const clickedSeeMore = () => {
        setShowNext(true);
        Store.homeExpanded = true;
        setTimeout(() => {
            myRef.current.scrollIntoView({
                block: "start",
                behavior: "smooth",
            });
        }, 120);
        window &&
            window.gtag &&
            window.gtag("event", "home-more", {
                value: 1,
            });
    };

    return (
        <Layout noActiveMenu={true}>
            <HelmetDatoCms seo={home.seoMetaTags} />

            <div className="home-page">
                <h1>
                    Senior <br />
                    Front-End <br />
                    Developer
                </h1>
                <HomeCentralComponent />
                <div
                    className="home-content"
                    dangerouslySetInnerHTML={{ __html: home.content }}
                />

                <button
                    ref={myRef}
                    className={`know-more ${
                        showNext || Store.homeExpanded ? "active" : ""
                    }`}
                    onClick={clickedSeeMore}
                >
                    Then <br />I can help
                    <br />
                    you &darr;
                </button>

                <div
                    style={{
                        display:
                            showNext || Store.homeExpanded ? "block" : "none",
                    }}
                    className="home-content"
                    dangerouslySetInnerHTML={{ __html: home.canHelp }}
                />
            </div>
        </Layout>
    );
};

export default HomePage;

export const query = graphql`
    query HomeQuery {
        home: datoCmsHomepage {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            slogan
            content
            canHelp
        }
    }
`;
