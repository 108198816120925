import React from "react";

export default function UxJsGraph({ withClipPath = false }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 400 250"
            className={withClipPath ? "withClipPath" : ""}
        >
            {withClipPath ? (
                <defs>
                    <clipPath id="clipped" className="clipPathTransformation">
                        <path d="M180,-40L220,-40L240,280L200,280" />
                    </clipPath>
                </defs>
            ) : null}

            <g
                id="ux-engineer"
                clipPath={withClipPath ? "url(#clipped)" : null}
            >
                <path
                    className="backg"
                    d="M0,60 L100,0 L200,60 L200,180 L100,240 L0,180"
                    fill="#03bff4"
                />

                <text
                    x="100"
                    y="122"
                    fill="#ffffff"
                    fontSize="30"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    className="text-ux"
                >
                    UX
                </text>

                <text
                    x="100"
                    y="148"
                    fill="#fff"
                    fontSize="11"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    className="text-sub-ux"
                >
                    the front of the front
                </text>
            </g>
            <g
                id="js-engineer"
                clipPath={withClipPath ? "url(#clipped)" : null}
            >
                <path
                    className="backg"
                    d="M200,60 L300,0 L400,60 L400,240 L300,180 L200,240"
                    fill="#fce72d"
                />

                <text
                    x="300"
                    y="122"
                    fill="#222"
                    fontSize="22"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    className="text-js"
                >
                    Javascript
                </text>

                <text
                    x="300"
                    y="148"
                    fill="#222"
                    fontSize="11"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    className="text-sub-js"
                >
                    the back of the front
                </text>
            </g>
        </svg>
    );
}
