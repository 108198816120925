import React from "react";

import UxJsGraph from "./ux_js_graph";

import "../styles/home.css";

export default class HomeCentralComponent extends React.PureComponent {
    render() {
        return (
            <div className="home-central-comp-holder">
                <div className="sizer">
                    <UxJsGraph />
                    <div className="augmented">
                        <UxJsGraph withClipPath={true} />
                    </div>
                </div>
            </div>
        );
    }
}
